.infinite-scroll-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;

  @media (min-width: 640px) { // sm breakpoint
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 768px) { // md breakpoint
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 1024px) { // lg breakpoint
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .image-card {
    border-radius: 0.375rem; // rounded-md
    border: 0;
    overflow: hidden;
    width: 100%;
    position: relative;
    img {
      width: 100%;
      transition: all 0.5s ease-in-out;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s ease-in-out;
      transform-origin: center center;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

}
